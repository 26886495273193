const employmentData = {
  cultural: [
    {
      name: "Tapply Inc",
      longName: "Tapply Inc",
      start: "10/2022",
      end: "01/2023",
      location: "Remote",
      positions: [
        {
          title: "👨‍💻 Frontend Engineer Intern",
          longTitle: "Frontend Engineer Intern",
          type: "Internship",
          responsibility: [
            "Architected React components for the message-sending experience to improve chat room experience",
            "Integrated Mixpanel event tracking and leveraged training in social sciences survey construction to build a user activity tracking system to provide KPI insight to company leadership",
            "Translated Figma design into modular and responsive React components",
            "Actively participated in live site reviews and collaborated with the team to align expectations and delivery estimates",
            "Proactively identified, proposed, and implemented process improvements to boost team efficiency",
            "Maintained and debugged the frontend code base with a can-do attitude",
            "Worked closely with other developers to ensured parallel performance across browsers, devices and PWA",
          ],
        },
      ],
      skills: ["JavaScript", "Next.js", "PWA", "React", "Mixpanel", "Figma"],
    },
    {
      name: "CHSA Museum",
      longName: "Chinese Historical Society of America Museum",
      start: "02/2021",
      end: "10/2021",
      location: "San Francisco, CA",
      positions: [
        {
          title: "🧑🏻‍💼 Coordinator",
          longTitle: "Education and Programs Coordinator",
          type: "FT Contactor",
          responsibility: [
            "Spearheaded online programs collaboratively with stakeholders with short turn arounds, and improved program engagement by 23%.",
            "Facilitated the integration of an outgoing exhibition Chinese American: Exclusion/Inclusion into Matterport and developed its virtual tour.",
            "Coordinated volunteers and intern at online and in-person events.",
          ],
        },
        {
          title: "Exhibition Research Intern",
          longTitle: "Exhibition Research Intern",
          type: "Internship",
          responsibility: [
            "Conducted the data collection, analysis, and visualization of material for the We Are Bruce Lee  exhibition and directly informed the exhibit curators’ design of the exhibition.",
          ],
        },
      ],
      skills: [
        "Survey Design",
        "Adobe Photoshop",
        "Data Analysis",
        "Data Visualization",
        "ATLAS.ti",
      ],
    },
    {
      name: "Smithsonian CFCH",
      longName: "Smithsonian Center for Folklife and Cultural Heritage",
      start: "07/2019",
      end: "12/2019",
      location: "Washington, DC",
      positions: [
        {
          title: "🧑🏻‍🏫 Research Intern",
          longTitle: "Cultural Sustainability Research Intern",
          type: "Internship",
          responsibility: [
            "Strengthened the organizations impact evaluation framework by providing write-up instructions for quantitative studies",
            "Conducted mix-methods data analysis of traditional Bhutanese textile consumption",
            "Drafted 3 project impact evaluation proposals to existing projects",
          ],
        },
      ],
      skills: [
        "Mixed Methods Data Analysis",
        "Data Visualization",
        "SPSS",
        "ATLAS.ti",
      ],
    },
    {
      name: "Kemuri Japanese Baru",
      longName: "Kemuri Japanese Baru",
      start: "11/2015",
      end: "05/2016",
      location: "Redwood City, CA",
      positions: [
        {
          title: "🤵🏻 Server",
          longTitle: "Server (Part Time)",
          type: "Part Time",
          responsibility: [
            "Served guest Michelin Star quality customer service in heart of the Silicon Valley.",
          ],
        },
      ],
      skills: ["Point of Sale System (POS)", "Customer Service"],
    },
    {
      name: "Azul Hospitality Group",
      longName: "Azul Hospitality Group",
      start: "09/2017",
      end: "12/2017",
      location: "Palo Alto, CA",
      positions: [
        {
          title: "🏨 Front Desk Agent",
          longTitle: "Front Desk Agent (Part Time)",
          type: "Part Time",
          responsibility: [
            "Greeted guests and handled all guest inquiries and requests.",
            "Upsold stays with food, beverage, and room upgrade options.",
          ],
        },
      ],
      skills: ["Central Reservation System (CRS)", "Customer Service"],
    },
    {
      name: "American Cruise Lines",
      longName: "American Cruise Lines",
      start: "03/2018",
      end: "04/2018",
      location: "Mississippi River",
      positions: [
        {
          title: "🛳️ Cruise Steward",
          longTitle: "Cruise Steward (Full Time)",
          type: "Internship",
          responsibility: [
            "Surpassed expectations of tables covered and demonstrated ability to identify slow areas, prioritize and flexibly assist where needed.",
            "Served guests on-board the America (185 guest capacity) in a hybrid banquet setting.",
          ],
        },
      ],
      skills: ["Teamwork", "Adaptability", "Customer Service"],
    },
  ],
  volunteer: [
    {
      name: "Little Tokyo Service Center",
      longName: "Little Tokyo Service Center",
      start: "09/2020",
      end: "08/2021",
      location: "Los Angeles, CA",
      positions: [
        {
          title: "🥡 LT Eats Delivery",
          longTitle: "LT Eats Delivery Volunteer",
          type: "Volunteer",
          responsibility: [
            "Delivered community subsidized COVID19 relief meals from Little Tokyo restaurants to seniors and low income households in the neighborhood twice a week",
          ],
        },
      ],
      skills: [],
    },
    {
      name: "David Kim for Congress (2020)",
      longName: "David Kim for Congress (2020)",
      start: "07/2020",
      end: "11/2020",
      location: "Los Angeles, CA",
      positions: [
        {
          title: "📮 Email Coordinator",
          longTitle: "Email Communication Coordinator (Volunteer)",
          type: "Volunteer",
          responsibility: [
            "Maintained and trimed constituent contact list, pruned inactive contact and improved email click count by 9% and bounce rate by 20%",
            "Coordinated with the social media to develop over all communication calendar",
          ],
        },
      ],
      skills: ["Microsoft Excel"],
    },
    {
      name: "ARCH",
      longName: "Alliance for the Restoration of Cultural Heritage",
      start: "11/2019",
      end: "02/2020",
      location: "Washington, DC",
      positions: [
        {
          title: "🗂 Project Coordinator",
          longTitle: "Citizen Historian Project Coordinator (Volunteer)",
          type: "Volunteer",
          responsibility: [
            "Assisted in the revitalization of an online network project (Citizen Historian) to facilitate the knowledge sharing of local history experts across the globe.",
          ],
        },
      ],
      skills: ["Project Management"],
    },
    {
      name: "Okayama University",
      longName: "Okayama University",
      start: "11/2016",
      end: "01/2027",
      location: "Okayama, Japan",
      positions: [
        {
          title: "🇺🇸 English Tutor",
          longTitle: "English Tutor Volunteer",
          type: "Volunteer",
          responsibility: [
            "Provided free one-on-one English conversation to 2 native Japanese speakers to pass TOEFL. My own experience of English language acquisition positioned me to pass on learning techniques that worked for me.",
          ],
        },
      ],
      skills: ["Tutoring"],
    },
  ],
  tech: [
    {
      name: "CHSA Museum",
      longName: "Chinese Historical Society of America Museum",
      start: "02/2021",
      end: "10/2021",
      location: "San Francisco, CA",
      positions: [
        {
          title: "🧑🏻‍💼 Coordinator",
          longTitle: "Education and Programs Coordinator",
          type: "FT Contactor",
          responsibility: [
            "Spearheaded online programs collaboratively with stakeholders with short turn arounds, and improved program engagement by 23%.",
            "Facilitated the integration of an outgoing exhibition Chinese American: Exclusion/Inclusion into Matterport and developed its virtual tour.",
            "Coordinated volunteers and intern at online and in-person events.",
          ],
        },
        {
          title: "Exhibition Research Intern",
          longTitle: "Exhibition Research Intern",
          type: "Internship",
          responsibility: [
            "Conducted the data collection, analysis, and visualization of material for the We Are Bruce Lee  exhibition and directly informed the exhibit curators’ design of the exhibition.",
          ],
        },
      ],
      skills: [
        "Survey Design",
        "Adobe Photoshop",
        "Data Analysis",
        "Data Visualization",
        "ATLAS.ti",
      ],
    },
  ],
};

export default employmentData;
