export const colors = {
  oliveGreen: '#606c38',
  darkGreen: '#283618',
  offWhite: '#faf8ed',
  lightBrown: '#dda15e',
  lightGray: '#c4c4c4',
  darkBrown: '#bc6c25',
  yellow: '#e8c564',
  red: '#db542e',
  offBlue: '#cae9ff',
  lightBlue: '#bee9e8',
  mediumBlue1: '#62b6cb',
  mediumBlue2: '#5fa8d3',
  darkBlue: '#1b4965',
  black: '#000000',
};
